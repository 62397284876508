import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"
import { sortByDate } from "../../util/dateUtil"
import PostType from "../../model/BlogType"
import { mapPost } from "../../../gatsby/transformers/blogTransformer"
import BlogPostCard from "./BlogPostCard"
import SecondaryHeader from "../../components/title/SecondaryHeader"

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding-top: 40px;
`

const BlogPosts = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding-top: 40px;
`

interface Props {
  posts: PostType[]
}

const BlogPage: FC<Props> = ({ posts }): JSX.Element => {
  const sorted = sortByDate(posts).filter(
    post => !post.categories.some(category => category.slug === "press-releases")
  )

  return (
    <Layout>
      <SEO title={"Blog"} description={"Social Supermarket blog"} />
      <Container>
        <SecondaryHeader className="">Blog</SecondaryHeader>
        <BlogPosts>
          {sorted.map(post => (
            <BlogPostCard post={post} key={post.id} />
          ))}
        </BlogPosts>
      </Container>
    </Layout>
  )
}

export default BlogPage
