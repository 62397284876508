import * as React from "react"
import { graphql } from "gatsby"
import BlogPage from "../social-supermarket/pages/blog/BlogPage"

const BlogPageTemplate = ({
  data: {
    allPost: { nodes },
  },
}): JSX.Element => <BlogPage posts={nodes} />

export default BlogPageTemplate

export const query = graphql`
  {
    allPost {
      nodes {
        uri
        id
        title
        date
        slug

        bannerImage {
          srcSet
          src
          sizes
          id
          alt
        }

        featuredImage {
          srcSet
          src
          sizes
          id
          alt
        }
        categories {
          slug
        }
      }
    }
  }
`
